'use client';

import Link from 'next/link';
import { BiPhoneCall } from 'react-icons/bi';
import { FaInstagramSquare } from 'react-icons/fa';
import { FaFacebookSquare } from 'react-icons/fa';
import { FaTiktok } from 'react-icons/fa6';
import { ActionIcon } from 'rizzui';

import { useSetting } from '@/providers/SettingsProvider';

const SocialLinks = () => {
    const settings = useSetting();

    return (
        <div className="social-container @container bg-gray-100 border-b-2">
            <div className="flex items-center justify-between px-8 py-1">
                <div className="flex items-center ">
                    <ActionIcon as="span" size="sm" variant="text">
                        <BiPhoneCall />
                    </ActionIcon>
                    <a
                        aria-label="Call us"
                        href={`tel:${settings.general.phone}`}
                    >
                        <p>{settings.general.phone}</p>
                    </a>
                </div>
                <div className="flex flex-row gap-2">
                    <Link
                        target="_blank"
                        href={settings.social.facebook}
                        aria-label="Visit us on Facebook"
                    >
                        <ActionIcon as="span" size="sm" variant="text">
                            <FaFacebookSquare />
                        </ActionIcon>
                    </Link>

                    <Link
                        target="_blank"
                        href={settings.social.instagram}
                        aria-label="Visit us on Instagram"
                    >
                        <ActionIcon as="span" size="sm" variant="text">
                            <FaInstagramSquare />
                        </ActionIcon>
                    </Link>
                    <Link
                        target="_blank"
                        href={settings.social.tiktok}
                        aria-label="Visit us on Tiktok"
                    >
                        <ActionIcon as="span" size="sm" variant="text">
                            <FaTiktok />
                        </ActionIcon>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default SocialLinks;
