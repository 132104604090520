'use client';

import Link from 'next/link';
import { FaFacebookSquare, FaInstagram } from 'react-icons/fa';
import { FaTiktok } from 'react-icons/fa6';

import { useSetting } from '@/providers/SettingsProvider';
import { IMainMenu } from '@/types/main-menus';

const Footer = ({ main_menus }: { main_menus: IMainMenu[] }) => {
    const settings = useSetting();
    const date = new Date();
    return (
        <footer className="py-8 text-white bg-gray-800">
            <div className="container px-6 mx-auto">
                <div className="grid grid-cols-1 gap-8 md:gap-24 md:grid-cols-3">
                    {/* Quick Links Column */}
                    <div>
                        <h3 className="mb-4 text-xl  text-gray-300 tracking-[.1rem]">
                            Quick Links
                        </h3>
                        <ul className="space-y-2">
                            {main_menus.map((menu) => (
                                <li key={menu.id}>
                                    <Link
                                        aria-label={menu.title}
                                        href={menu.url}
                                        className="text-gray-300  tracking-[.1rem] hover:text-gray-100"
                                    >
                                        {menu.title}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div>
                        <h3 className="mb-4 text-xl  text-gray-300 tracking-[.1rem]">
                            Contact Info
                        </h3>
                        <p className="mb-2 text-gray-300  tracking-[.1rem] hover:text-gray-100">
                            Email: {settings.general.email}
                        </p>
                        <p className="mb-2 text-gray-300  tracking-[.1rem] hover:text-gray-100">
                            Phone: {settings.general.phone}
                        </p>
                        <p className="mb-2 text-gray-300  tracking-[.1rem] hover:text-gray-100">
                            Address: {settings.general.address}
                        </p>
                    </div>

                    <div>
                        <h3 className="mb-4 text-xl  text-gray-300 tracking-[.1rem]">
                            Follow Us
                        </h3>
                        <div className="flex space-x-6">
                            <Link
                                target="_blank"
                                aria-label="Visit us on Facebook"
                                href={settings.social.facebook}
                                className="text-gray-300  tracking-[.1rem] hover:text-gray-100"
                            >
                                <FaFacebookSquare className="w-4 h-4" />
                            </Link>
                            <Link
                                target="_blank"
                                aria-label="Visit us on Instagram"
                                href={settings.social.instagram}
                                className="text-gray-300  tracking-[.1rem] hover:text-gray-100"
                            >
                                <FaInstagram className="w-4 h-4" />
                            </Link>
                            <Link
                                target="_blank"
                                aria-label="Visit us on Tiktok"
                                href={settings.social.tiktok}
                                className="text-gray-300  tracking-[.1rem] hover:text-gray-100"
                            >
                                <FaTiktok className="w-4 h-4" />
                            </Link>
                        </div>
                    </div>
                </div>

                {/* Footer Bottom: Copyright */}
                <div className="pt-4 mt-8 text-sm  text-center border-t border-gray-700 tracking-[.1rem]">
                    <p>
                        &copy; {date.getFullYear()} {settings.general.copyright}
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
