'use client';

import { useEffect } from 'react';
import { Drawer } from 'rizzui';

import { useDrawer } from '@/hooks/useDrawer';

export default function GlobalDrawer() {
    const { isOpen, view, placement, customSize, closeDrawer } = useDrawer();
    useEffect(() => {
        closeDrawer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Drawer
            isOpen={isOpen}
            onClose={closeDrawer}
            placement={placement}
            customSize={customSize}
            overlayClassName="dark:bg-opacity-40 dark:backdrop-blur-md"
            containerClassName="dark:bg-gray-100"
            className="z-[9999]"
        >
            {view}
        </Drawer>
    );
}
