import(/* webpackMode: "eager" */ "/home/runner/work/evas-next/evas-next/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/evas-next/evas-next/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/home/runner/work/evas-next/evas-next/node_modules/react-hot-toast/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Button","ActionIcon"] */ "/home/runner/work/evas-next/evas-next/node_modules/rizzui/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/home/runner/work/evas-next/evas-next/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/evas-next/evas-next/src/components/footer/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/evas-next/evas-next/src/components/global-drawer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/evas-next/evas-next/src/components/hamburger-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/evas-next/evas-next/src/components/header/announcement-bar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/evas-next/evas-next/src/components/header/social-links.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/evas-next/evas-next/src/components/share-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SettingsProvider"] */ "/home/runner/work/evas-next/evas-next/src/providers/SettingsProvider.tsx");
